import React, { Component } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Copy } from 'react-feather';

class CreativeTextLink extends Component {

    _isMounted = false;

    state = {
        isCreativeSelected: false,
        currentCreativeElements: [],
        selectedCreativeId: ''
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.creativeLinks.map(clink =>
            this.getCreativeTextData(clink)
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    creativeSelectedHandler = (event) => {
        this.setState({
            isCreativeSelected: true,
            selectedCreativeId: [event.currentTarget.value]
        }, () => {
            this.props.creativeSelected(this.state);
        });
    }

    getCreativeTextData = (clink) => {
        const authtoken = localStorage.getItem('mb-auth-token');

        fetch(`${process.env.REACT_APP_API_URL}/trackinglink`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'x-app-token': authtoken
            },
            body: JSON.stringify({
                campaign_id: this.props.campaign_id,
                traffic_type: this.props.selectedTrafficType,
                creative_id: clink.creative_id
            })
        })
            .then(res => res.json())
            .then(result => {
                if (this._isMounted) {
                    if (result["success"] === true) {

                        let currentCreativeElements = [...this.state.currentCreativeElements];
                        // Saving both, tracking_link and creative_id to state since creative_id will be the value of particular text creative. When clicked upon, that Id will be sent to generate a link
                        currentCreativeElements.push({ "tracking_link": result["tracking-link"], "creative_id": clink.creative_id });

                        this.setState({ currentCreativeElements });
                    } else if (result["success"] === false) {
                        if (result.code !== 401) {
                            this.props.showSystemMessage("error", result.errors.message);
                        }
                    } else {
                        this.props.showSystemMessage("error", result.error.message);
                    }
                }
            }).catch(error => {
                this.props.showSystemMessage("error", "Connection error.");
            });
    }

    render() {
        return (
            <div className="Banner">
                <div className="modal-header">
                    <h5 className="modal-title">{this.props.mode === 'tracking' ? <React.Fragment>Select a Text Creative</React.Fragment> : <React.Fragment>Text Creatives</React.Fragment>}</h5>
                    <button type="button" className="close" onClick={this.props.modalClosed}>
                        <span aria-hidden="true">x</span>
                    </button>
                </div>
                <div className="modal-body">

                    {this.state.currentCreativeElements.map(clink => {
                        return (
                            <div className="card my-2" key={clink.creative_id}>
                                <div className="card-body d-flex">
                                    <div className="flex-1  d-flex align-items-center w-100">
                                        <div style={{ textDecoration: "underline" }} dangerouslySetInnerHTML={{ __html: clink.tracking_link }} />
                                    </div>
                                    {this.props.mode === 'tracking' ?
                                        <div className="d-flex align-items-center p-3">                                        
                                            <button className="creativeSelect btn btn-outline-dark" value={clink.creative_id} onClick={this.creativeSelectedHandler} >Select</button >                                        
                                        </div>
                                    :
                                        <div className="d-flex align-items-center p-3">         
                                            <CopyToClipboard text={clink.tracking_link}>
                                                <button className="btn btn-outline-primary btn-sm">
                                                    <Copy size={16} /><span style={{ paddingLeft: "5px", paddingTop: "50px" }}>Copy</span>
                                                </button>
                                            </CopyToClipboard>
                                        </div>  
                                    }
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={this.props.modalClosed}>Close</button>
                </div>
            </div>
        );
    }
}

export default CreativeTextLink;
