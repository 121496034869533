import React, { Component } from 'react';
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';

class FormConfirmation extends Component {

    componentDidMount(){
        ReactPixel.track('Lead');
        window.gtag('event', 'conversion', {'send_to': 'AW-10796151400/bZ-zCOfJ8q0DEOjkgJwo'});
        LinkedInTag.track('17776460');
    }

    render() {
        return (
            <div className="card p-3">
                <h3 className="text-center">Thank you for registering to MaxBounty.</h3>
                <hr></hr>
                <div>
                    <div className="text-left">
                        <p>Here are the next steps in your application:</p>
                        <p className="font-weight-bold">Step 1: Confirm your application.</p>
                        <p>Within a few minutes, you will receive an e-mail from affiliates@maxbounty.com. This e-mail will contain a link that you must click to confirm that you have applied for an account with MaxBounty. If you do not receive this e-mail, please check your Junk/Spam folder.</p>
                        <p>If you still have not received this e-mail within an hour, please <a href="mailto:affiliates@maxbounty.com">contact us</a> and let us know.</p>
                    </div>
                    <hr></hr>
                    <div className="text-left">
                        <p className="font-weight-bold">Step 2: Application Review.</p>
                        <p>Your application will be reviewed by our compliance and affiliate management teams. If your application is appropriate, your will be contacted for a telephone interview.</p>                    
                    </div>
                    <hr></hr>
                    <div className="text-left">
                        <p className="font-weight-bold">Step 3: Phone Interview.</p>
                        <p>All applicants must complete a telephone interview with a MaxBounty affiliate manager. Instructions on how to reach an affiliate manager will be provided once you have confirmed your application.</p>                    
                    </div>
                </div>
            </div>
        );
    }
}
 
export default FormConfirmation;